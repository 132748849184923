import { Box, Typography } from "@mui/material";
import React from "react";
import { globalStyles, headerStyles } from "../theme";

function Header() {
  return (
    <Box id="home" sx={headerStyles.container}>
      <Typography sx={globalStyles.title}>Open Key Systems</Typography>
      <Typography sx={headerStyles.subtitle}>
        {" "}
        Unlock a World of Trust
      </Typography>
      <Box sx={headerStyles.body}>
        <Box sx={headerStyles.contactBox}>
          <Typography>
            The decentralized identity platform that puts you in control.
          </Typography>
          <Typography>
            In a world of ever-growing digital threats, OpenKey Systems offers a
            secure, decentralized solution. Take back control of your identity
            and access everything with a single, trusted key.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default Header;
