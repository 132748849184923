import { Box, Typography } from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import React from "react";
import { contactDetails, globalStyles } from "../theme";

function Footer() {
    return (
      <footer>
        <Box sx={globalStyles.footer}>
          <Typography>
            &copy; {new Date().getFullYear()} Open Key Systems. All rights
            reserved.
          </Typography>
          {contactDetails.map((detail, index) => (
            <Typography key={index}>{detail}</Typography>
          ))}
          <Box sx={globalStyles.contactInfo}>
            <a
              href="https://www.instagram.com"
              target="_blank"
              rel="noreferrer"
            >
              <InstagramIcon sx={globalStyles.icon} />
            </a>
            <a href="https://www.facebook.com" target="_blank" rel="noreferrer">
              <FacebookIcon sx={globalStyles.icon} />
            </a>
            <a href="https://www.linkedin.com" target="_blank" rel="noreferrer">
              <LinkedInIcon sx={globalStyles.icon} />
            </a>
          </Box>
        </Box>
      </footer>
    );
}

export default Footer;
