import { TextField } from "@mui/material";
import React from "react";
import { globalStyles } from "../theme";

function CustomTextField({ label, rows = 1, multiline = false }) {
    return (
        <TextField
            label={label}
            fullWidth
            required
            margin="normal"
            rows={rows}
            multiline={multiline}
            sx={globalStyles.formInput}
            slotProps={{
                htmlInput: { style: globalStyles.inputColor },
                inputLabel: { style: globalStyles.labelColor },
            }} />
    );
}

export default CustomTextField;
