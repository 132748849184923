import {
  AppBar,
  Button,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  ThemeProvider,
  Toolbar,
  createTheme,
  styled,
} from "@mui/material";
import { React, useState } from "react";
import { Menu as MenuIcon } from "@mui/icons-material";
import "../styles.css";
import { buttonStyles } from "../theme";
import { scrollToSection } from "./SharedFunctions";

const theme = createTheme({
  palette: {
    mode: "dark",
  },
});

const StyledButton = styled(Button)(buttonStyles.button);
function Navbar() {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <ThemeProvider theme={theme}>
      <AppBar position="fixed">
        <Toolbar>
          <IconButton color="inherit" onClick={toggleDrawer}>
            <MenuIcon />
          </IconButton>
          <div className="nav-buttons">
            <StyledButton onClick={() => scrollToSection("home")}>
              Home
            </StyledButton>
            <StyledButton onClick={() => scrollToSection("valueProposition")}>
              Value Proposition
            </StyledButton>
            <StyledButton onClick={() => scrollToSection("contact")}>
              Contact
            </StyledButton>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer anchor="left" open={isDrawerOpen} onClose={toggleDrawer}>
        <List>
          <ListItemButton onClick={() => scrollToSection("home")}>
            <ListItemText primary="Home" />
          </ListItemButton>
          <ListItemButton onClick={() => scrollToSection("valueProposition")}>
            <ListItemText primary="Value Proposition" />
          </ListItemButton>
          <ListItemButton onClick={() => scrollToSection("contact")}>
            <ListItemText primary="Contact" />
          </ListItemButton>
        </List>
      </Drawer>
    </ThemeProvider>
  );
}

export default Navbar;
