import { Box, Button, Container, Typography } from "@mui/material";
import CustomTextField from "./CustomTextField";
import { globalStyles, buttonStyles } from "../theme";

function ContactForm() {
    return (
        <Container id="contact" sx={globalStyles.container}>
            <Typography sx={globalStyles.title}>Contact Us</Typography>
            <Box component="form" sx={globalStyles.form}>
                <CustomTextField label="Name" name="name" />
                <CustomTextField label="Email" name="email" />
                <CustomTextField
                    label="Leave us a message..."
                    name="message"
                    rows={4}
                    multiline={true} />
                <Button sx={buttonStyles.button}>Submit!</Button>
            </Box>
        </Container>
    );
}

export default ContactForm;
