import { Box, Typography } from "@mui/material";
import React from "react";
import { globalStyles, valuePropositionStyles } from "../theme";

function ValueProposition() {
  return (
    <Box id="valueProposition" sx={valuePropositionStyles.container}>
      <Typography sx={valuePropositionStyles.title}>Our Values</Typography>

      <Box sx={valuePropositionStyles.contentBox}>
        <Typography sx={globalStyles.content}>
          Security: We are committed to providing a highly secure platform that
          protects user data and prevents unauthorized access.
        </Typography>
        <Typography sx={globalStyles.content}>
          Privacy: We believe in user control over personal data and prioritize
          privacy-enhancing technologies.
        </Typography>
        <Typography sx={globalStyles.content}>
          Transparency: We operate with openness and accountability, ensuring
          clear communication and responsible data handling.
        </Typography>
        <Typography sx={globalStyles.content}>
          Innovation: We continuously strive to improve and expand our platform
          with cutting-edge technologies and user-centric design.
        </Typography>
        <Typography sx={globalStyles.content}>
          Inclusivity: We aim to create a platform that is accessible and
          beneficial to everyone, regardless of their technical expertise or
          background.
        </Typography>
        <Typography sx={globalStyles.content}>
          Self-Sovereign Identity: You own and control your data. hello
        </Typography>
        <Typography sx={globalStyles.content}>
          Universal Access: One key for all your apps and services.
        </Typography>
        <Typography sx={globalStyles.content}>
          Enhanced Security: Decentralized architecture for superior protection.
        </Typography>
      </Box>
    </Box>
  );
}

export default ValueProposition;
