import { Box, Typography } from "@mui/material";
import React from "react";
import { globalStyles } from "../theme";

/**
 * AboutUs component renders the "About Us" section of the landing page.
 * It includes a title and three sections, each with a title and content.
 *
 * @component
 * @example
 * return (
 *   <AboutUs />
 * )
 */
function AboutUs() {
  return (
    <Box>
      <Typography sx={globalStyles.title}>About Us</Typography>
      <Box sx={globalStyles.aboutUsWrapper}>
        <Box sx={globalStyles.aboutUsSection}>
          <Typography sx={globalStyles.aboutUsSectionTitle}>
            Who We Are
          </Typography>
          <Typography sx={globalStyles.aboutUsSectionContent}>
            OpenKey Systems is building the future of digital identity. We
            provide a decentralized identity platform that empowers individuals
            and businesses with security, privacy, and control. Our mission is
            to create a more trusted and interconnected digital world where
            everyone can seamlessly and securely access the resources they need.
          </Typography>
        </Box>
        <Box sx={globalStyles.aboutUsSection}>
          <Typography sx={globalStyles.aboutUsSectionTitle}>
            Our Mission
          </Typography>
          <Typography sx={globalStyles.aboutUsSectionContent}>
            To revolutionize digital identity by providing a secure,
            user-centric, and globally accessible platform that fosters trust
            and empowers individuals and organizations.
          </Typography>
        </Box>
        <Box sx={globalStyles.aboutUsSection}>
          <Typography sx={globalStyles.aboutUsSectionTitle}>
            Our Vision
          </Typography>
          <Typography sx={globalStyles.aboutUsSectionContent}>
            A world where digital identity is secure, privacy-preserving, and
            universally accessible, enabling seamless and trusted interactions
            in the digital realm.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default AboutUs;
