import "./styles.css";
import { Box } from "@mui/material";

import { ThemeProvider } from "@emotion/react";
import Header from "./components/Header";
import ValueProposition from "./components/ValueProposition";
import Products from "./components/Products";
import AboutUs from "./components/AboutUs";
import ContactForm from "./components/ContactForm";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import { theme } from "./theme";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Box>
        <Navbar />
        <Header />
        <ValueProposition />
        <Products />
        <AboutUs />
        <ContactForm />
        <Footer />
      </Box>
    </ThemeProvider>
  );
}

export default App;
