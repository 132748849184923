import { Box, Button, Container, Typography } from "@mui/material";
import React from "react";
import imgEnterprise from "../Assets/enterprise.png";
import imgConsumer from "../Assets/consumer.png";
import imgGovernment from "../Assets/government.png";
import { buttonStyles, globalStyles } from "../theme";

function Products() {
  return (
    <Container>
      <Typography sx={globalStyles.title}>Our Products</Typography>

      <Box sx={globalStyles.productContainer}>
        <Box sx={globalStyles.productBox}>
          <img
            src={imgEnterprise}
            alt="enterprise solutions"
            style={globalStyles.productImage}
          />
          <Typography sx={globalStyles.productTitle}>For Businesses</Typography>
          <Typography sx={globalStyles.description}>
            Streamline authentication, reduce fraud, and enhance customer trust
            with OpenKey Systems. Our decentralized identity platform provides a
            secure and efficient solution for businesses of all sizes. Improve
            security, reduce costs, and enhance customer experience with our
            cutting-edge technology.
          </Typography>
          <Button sx={buttonStyles.productButton}>Get!</Button>
        </Box>
        <Box sx={globalStyles.productBox}>
          <img
            src={imgConsumer}
            alt="consumer solutions"
            style={globalStyles.productImage}
          />
          <Typography sx={globalStyles.productTitle}>For People</Typography>
          <Typography sx={globalStyles.description}>
            Drowning in Passwords? Losing Control of Your Data? In a world of
            ever-growing digital threats, OpenKey Systems offers a secure,
            decentralized solution. Take back control of your identity and
            access everything with a single, trusted key.
          </Typography>
          <Button sx={buttonStyles.productButton}>Get!</Button>
        </Box>
        <Box sx={globalStyles.productBox}>
          <img
            src={imgGovernment}
            alt="government solutions"
            style={globalStyles.productImage}
          />
          <Typography sx={globalStyles.productTitle}>
            For Governments
          </Typography>
          <Typography sx={globalStyles.description}>
            OpenKey Systems provides governments a secure, decentralized
            identity platform to enhance citizen services and reduce fraud. By
            giving citizens control over their data, OpenKey Systems fosters
            transparency and enables cost savings through reduced fraud and
            administrative burden.This strengthens identity verification,
            protects against cyberattacks, and streamlines access to services,
            improving efficiency and public trust.
          </Typography>
          <Button sx={buttonStyles.productButton}>Get!</Button>
        </Box>
      </Box>
    </Container>
  );
}

export default Products;
