import { createTheme } from "@mui/material";

export const theme = createTheme({
  typography: {
    fontFamily: "Montserrat",
    palette: {
      mode: "dark",
    },
  },
});

export const contactDetails = [
  "123 Main Street, City, State, Country",
  "+44 7777 777 777",
  "info@openkeysystems.com",
];
const globalColors = {
  primary: "#003eff",
  secondary: "#006eff",
  tertiary: "#1A2239",
  white: "white",
};

export const globalStyles = {
  colors: globalColors,
  buttonBase: {
    marginLeft: "1rem",
    backgroundColor: globalColors.primary,
    padding: "0.5rem 1rem",
    borderRadius: "2rem",
    border: 0,
    color: "white",
    fontWeight: "bold",
    boxShadow: "none",
    transition: "transform 0.3s",
    "&:hover": {
      transform: "scale(1.05)",
      backgroundImage: "linear-gradient(to bottom, #003eff, #006eff)",
    },
  },
  title: {
    fontSize: "3rem",
    textAlign: "center",
    marginBottom: "2rem",
    "@media (max-width: 442px)": {
      fontSize: "1.5rem",
    },
  },
  subtitle: {
    fontSize: "2rem",
    textAlign: "center",
    marginBottom: "1rem",
    "@media (max-width: 442px)": {
      fontSize: "1.1rem",
    },
  },
  content: {
    fontWeight: "light",
    marginBottom: "0.1rem",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    marginRight: "10px",
    color: "white",
    transition: "transform 0.3s ease",
    "&:hover": {
      color: "rgb(0,0,255)",
    },
  },
  form: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: "100%",
  },
  formInput: {
    marginBottom: "1rem",
  },
  formButton: {
    marginTop: "1rem",
  },
  inputColor: { color: globalColors.white },
  labelColor: { color: globalColors.white },
  footer: {
    textAlign: "left",
    padding: "1rem",
    lineHeight: "3rem",
    backgroundColor: globalColors.tertiary,
  },
  aboutUsWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "2rem",
  },
  aboutUsSectionTitle: {
    fontWeight: "bold",
    fontSize: "2rem",
    maxWidth: "50%",
  },
  aboutUsSection: {
    marginBottom: "2rem",
    maxWidth: "50%",
  },
  productContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    textAlign: "center",
    width: "100%",
    gap: "1rem",
    marginBottom: "2rem",
  },
  productTitle: {
    width: "15rem",
    fontSize: "1.5rem",
  },
  productImage: {
    width: "15rem",
    height: "auto",
    borderRadius: "10px",
  },
  productBox: {
    transition: "0.3s",
    "&:hover": {
      transform: "scale(1.05)",
    },
  },
  description: {
    marginBottom: "1rem",
    fontSize: "1rem",
    width: "15rem",
    fontWeight: "light",
  },
  contactInfo: {
    marginTop: "1rem",
  },
};

export const buttonStyles = {
  button: {
    ...globalStyles.buttonBase,
    marginBottom: "1rem",
  },
  productButton: {
    ...globalStyles.buttonBase,
    backgroundColor: globalStyles.colors.primary,
    marginTop: "1rem",
  },
};

export const footerStyles = {
  title: {
    ...globalStyles.title,
    fontWeight: "bold",
    fontSize: "1.5rem",
  },
};

export const headerStyles = {
  container: {
    marginTop: "6rem",
    marginBottom: "1rem",
    padding: "1rem",
    maxWidth: "100%",
    overflow: "hidden",
    alignItems: "center",
  },
  subtitle: {
    fontSize: "1.5rem",
    textAlign: "center",
    marginTop: "1rem",
    marginBottom: "1rem",
    "@media (max-width: 442px)": {
      fontSize: "1rem",
    },
  },
  heroImage: {
    width: "50vw",
    height: "auto",
    borderRadius: "10px",
  },
  contactBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
};

export const productStyles = {
  productContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    width: "100%",
    textAlign: "center",
    gap: "1rem",
    marginBottom: "2rem",
  },
  image: {
    width: "15rem",
    height: "auto",
    borderRadius: "10px",
  },
  productBox: {
    transition: "0.3s",
    "&:hover": {
      transform: "scale(1.05)",
    },
  },
};

export const valuePropositionStyles = {
  container: {
    marginTop: "6rem",
    textAlign: "center",
    marginBottom: "2rem",
  },
  title: {
    ...globalStyles.title,
    fontWeight: "bold",
    padding: "0.5rem 1rem", // Add padding to the text
    borderRadius: "10px",
    "@media (max-width: 442px)": {
      fontSize: "2rem",
    },
  },
  contentBox: {
    marginTop: "2rem",
    backgroundColor: globalStyles.colors.tertiary,
    padding: "2rem",
    borderRadius: "10px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)",
  },
  contentTitle: {
    marginTop: "1.5rem",
    fontWeight: "bold",
    marginBottom: "1.5rem",
  },
  content: {
    fontWeight: "light",
    marginBottom: "0.1rem",
  },
};

export const navBarStyles = {
  button: {
    ...buttonStyles.button,
  },
};
